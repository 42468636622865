import React from 'react'
import LaptopIcon from '@material-ui/icons/Laptop'
import WebIcon from '@material-ui/icons/Web'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import CodeIcon from '@material-ui/icons/Code'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import FunctionsIcon from '@material-ui/icons/Functions'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

const Products = () => {
    return (
        <section className="product">
            <div className="containerDiv">
                <h2>Sneak peek</h2>
            </div>
            <p className="sm:text-xl text-textColor m-3">Some of the awesome features and functionalities we are developing for your delight.</p>
            <div className="products">
                <div className="items">
                    <LaptopIcon className="icons"/>
                    <h4>Build your <br/> Workspaces</h4>
                </div>
                <div className="items">
                    <WebIcon className="icons"/>
                    <h4>Browse with <br/>Proxies</h4>
                </div>
                <div className="items">
                    <DesktopWindowsIcon className="icons"/>
                    <h4>Customizable Split Screens</h4>
                </div>
                <div className="items">
                    <CodeIcon className="icons"/>
                    <h4>Configurable Java Scripts on page load</h4>
                </div>
                <div className="items">
                    <NotificationsActiveIcon className="icons"/>
                    <h4>Manage <br/>Notifications</h4>
                </div>
                <div className="items">
                    <SpeakerNotesIcon className="icons"/>
                    <h4>To Do List and <br/> Notes</h4>
                </div>
                <div className="items">
                    <MultilineChartIcon className="icons"/>
                    <h4>Graphical Memory Usage</h4>
                </div>
                <div className="items">
                    <FunctionsIcon className="icons"/>
                    <h4>Screen and Workspaces Hibernation</h4>
                </div>
                <div className="items">
                    <SupervisorAccountIcon className="icons"/>
                    <h4>Profile <br/> Manager</h4>
                </div>
            </div>
        </section>
    )

}
export default Products