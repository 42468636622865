/* eslint-disable react/prop-types */
import React, { useState } from 'react'

const Introduction = () => {
    return (
        <header className="animate-fade-in-up bg-no-repeat bg-center bg-cover resize">
            <div className="py-14 md:py-32 bg-opacity-50 bg-black flex items-center justify-center" style={{background:'rgba(0,0,0,0.5)'}}>
                <div className="mx-2 text-center">
                    <h1 className="animate-bounce mb-4 text-gray-200 text-xl md:text-2xl lg:text-3xl">
                        Become a <span className="font-extrabold">multi-tasking browsing grand master !</span>
                    </h1>

                    <h1 className="text-gray-100 mb-3 font-bold text-xl md:text-2xl lg:text-3xl">
                    Enjoy multi profile multi proxy browsing sessions, build and organize <br/> your workspaces as they best fit your daily operations, <br/> create and customize splitted screens to monitor different workspaces at the same time.
                    </h1>
                </div>
            </div>
        </header>
    )
}
export default Introduction