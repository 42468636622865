/* eslint-disable react/prop-types */
import React, {useState} from 'react'
import FadeIn from 'react-fade-in'
import Countdown from '../countdown'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const ComingSoon = () => {
    const [ email, setEmail ] = useState()
    const [ error, setError ] = useState()
    const [ subscribed, setSubscribed ] = useState(false)
    const [ msg, setMsg ] = useState('')

    const subscribeEmail = (e) => {
        setEmail(e)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('email',e)
        if(email) {
            addToMailchimp(email)
                .then(data => {
                    console.log('data',data.msg)
                    if(data.msg) {
                        setSubscribed(true)
                        const split = data.msg.split('<')
                        setMsg(split[0])
                    }
                })
                .catch(err => console.log('eerr',err))
        }
        else {
            setError('Please enter your email first and then click subscribe.')
        }
        
    }
    return(
        <div >
            <section className="animate-fade-in-up p-3 pt-5 bg-yellowColor text-left text-mainColor" >
                <FadeIn delay="300" transitionDuration="700">
                    <h1 className=" mb-4 font-extrabold text-center sm:text-xl">
                       We are getting ready to launch in:  
                    </h1>
                    <Countdown/>
                    <h1 className=" my-4 text-center font-extrabold sm:text-xl">
                    We’re currently working on building an amazing new browser for you.
                    </h1>
                    <h1 className=" mb-4 text-center font-extrabold sm:text-xl">
                    Request your early invitation to join our community and get the latest updates on product development <br/> and access to amazing promotions through our Newsletter. 
                    </h1>
                    <form className=" flex flex-wrap justify-center w-full" onSubmit={(e)=>handleSubmit(e)}>
                        <input onChange={(e)=>subscribeEmail(e.target.value)} className="bg-gray-100 w-4/5 mb-2 md:mb-0 md:w-1/4 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none" type="email" required placeholder="Enter email"/>
                        <button  className="bg-mainColor font-bold md:ml-6  text-white transition-shadow py-2 px-2 rounded" type="submit">Join Community</button>
                    </form>
                    {error ? <p className="text-mainColor text-center mt-2 font-bold xs:text-md md:text-lg">{error}</p> : null}
                    {subscribed ? <p className="text-mainColor text-center mt-2 font-bold xs:text-md md:text-lg">{msg}</p> : null}
                </FadeIn>
                
            </section>
        </div>
    )
}
export default ComingSoon