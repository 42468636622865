import React, {useEffect,useState} from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SubscribeModal from '../components/modals/subscribeModal'
import RictaBrowser from '../components/homeComponents/rictaBrowser'
import Benefits from '../components/homeComponents/benefits'
import Products from '../components/homeComponents/products'
import Introduction from '../components/homeComponents/introduction'
import ComingSoon from '../components/homeComponents/comingSoon'

const IndexPage = () => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false) 
    const modal = () => {
        setModalIsOpen(true)
    }
    useEffect(()=>{
        let pop_status = sessionStorage.getItem('pop_status')
        if(!pop_status){
            setTimeout(modal,18000)
            sessionStorage.setItem('pop_status',1)
        }
    },[])
    return (
        <div>
            <Layout>
                <Seo title="Home" description="Home page BricoBrowser" /> {/*svaku stranicu wrap u layout i seo i daj joj durgaciji naslov i opis*/}
                <ComingSoon/>
                <Introduction/>
                <RictaBrowser/>
                <Benefits/>
                <Products/>
                {modalIsOpen && <SubscribeModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>}
            </Layout>
        </div>
    )
}

export default IndexPage
