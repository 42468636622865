import React from 'react'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import WebIcon from '@material-ui/icons/Web'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import Fade from 'react-reveal/Fade'

const Benefits = () => {
    return (
        <section className="benefit">
            <h2>Top 3 benefits for You!</h2>
            <div className="cont">
                <hr className="horizontalLine"/>
                <div className="rictos">
                    <Fade bottom>
                        <div className="benefits mb-3">
                            <WebIcon className="icons"/>
                            <p>Increase productivity by superior browsing session organization into Workspaces and Screen visualization customization.</p>
                        </div>
                    </Fade>
                    <Fade top>
                        <div className="benefits mb-3">
                            <SupervisorAccountIcon className="icons"/>
                            <p>Simplify your work by parallel multiple social media profile logins and manage your notifications so you don’t miss a thing. </p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="benefits mb-3">
                            <ImportantDevicesIcon className="icons"/>
                            <p>Maintain anonymity and get access to online content from  various countries through intelligent proxy setups. </p>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}
export default Benefits