import React from 'react'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent'
import WorkIcon from '@material-ui/icons/Work'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import Fade from 'react-reveal/Fade'

const RictaBrowser = () => {
    return (
        <section className="intro">
            <div className="grid-container">
                <div className="grid-item">
                    <div className="container">
                        <div className="left">
                            <EmojiObjectsIcon className="icons"/>
                        </div>
                        <Fade right>
                            <div className="right">
                                <div className="rictaText">Do you listen to music or watch movie while browsing the web, replying to email and scrolling your social media newsfeed?
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div className="container">
                        <div className="left">
                            <WorkIcon className="icons"/>
                        </div>
                        <Fade right>
                            <div className="right">
                                <div className="rictaText" >We all get tired flipping through different browsers and logging in/out from our multiple identity profiles. With BricoBrowser, social media-multitasking is made easy. 
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div className="container">
                        <div className="left">
                            <DesktopWindowsIcon className="icons"/>
                        </div>
                        <Fade right>
                            <div className="right">
                                <div className="rictaText">Do you need to access online content from multiple geolocation at the same time and find it very frustrating to use multiple virtual machine or changing VPN each time? BricoBrowser lets you have in the same window up to 4 screens, each of them using a different identity and a different proxy.</div>
                            </div>
                        </Fade>
                    </div>
                    <div className="container">
                        <div className="left">
                            <WbIncandescentIcon className="icons"/>
                        </div>
                        <Fade right>
                            <div className="right">
                                <div className="rictaText" >BricoBrowser was built to help you!
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                {/* <div className="grid-item">
                    <img src={RictaImg}  alt="rictaImg"/>
                </div> */}
            </div>
        </section>
    )
}
export default RictaBrowser